.standard-shadow:hover{
    box-shadow: 0 0.5rem 1.5rem #bbbbbb !important; 
}

.standard-shadow{
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; 
}

.chosen-shadow:hover{
    box-shadow: 0 0.5rem 1.5rem #38469b !important;
}

.chosen-shadow{
    box-shadow: 0 0.5rem 1.5rem #afb4d7 !important;
}

.shadow-transition{
    transition: box-shadow .3s ease;
}

html{
    scroll-behavior: smooth;
}

.clickable:hover {
    background-color: rgba(255, 255, 255, 0.877);
}

.react-datepicker__week-number{
    color: #cbcbcb !important

}


.react-datepicker__day-name{
    color: #cbcbcb !important
}

.react-datepicker__current-month{
    color: #38469b !important
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
